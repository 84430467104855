<ng-template #clauseTitle>
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'PROPOSAL.CONTRACT.CLAUSE.ADD'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="quality-form" #clauseTitleForm="ngForm" (ngSubmit)="clauseTitleForm.valid && accept()">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'PROPOSAL.CONTRACT.CLAUSE.TITLE'|translate }}</label>
            <input class="form-control" name="titulo" [(ngModel)]="title" required autocomplete="off"
            [modalFocus]="true">
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="quality-form" class="btn btn-agree" [disabled]="clauseTitleForm.invalid">
      {{ 'PROPOSAL.CONTRACT.CLAUSE.ADD'|translate }}
    </button>
  </div>
</ng-template>