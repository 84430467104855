import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JSend } from '../models/jsend.model';
import { PusherMessage } from '../models/pusher-message.model';
import { PusherService } from './pusher.service';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  private baseUrl: string = '/:apiBase/companies/:companyId/metadata/:entity/:entityId';

  private _collectionSubjects: { [companyId: number]: BehaviorSubject<{ [key: string]: MetaDataField }> } = {};

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  public watch(companyId: number, entity: string, entityId: string | number): Observable<{ [key: string]: MetaDataField }> {
    const id: string = entityId.toString();
    const eventKey = [companyId, entity, id].join('_');

    return this.pusherService.subjectManager(
      {
        collection: this._collectionSubjects,
        key: eventKey,
        getData: () => this.get(companyId, entity, id)
      },
      {
        channel: `company_${companyId}`,
        event: 'metadata',
        condition: (event: PusherMessage) => !event.data || (event.data.entity == entity && event.data.entity_id == id)
      });
  }

  private get(companyId: number, entity: string, entityId: string): Observable<{ [key: string]: MetaDataField }> {
    const url = this.baseUrl
      .replace(":companyId", companyId.toString())
      .replace(":entity", entity)
      .replace(":entityId", entityId);

    const stream = this.http.get<JSend<{
      metadata: { [key: string]: MetaDataField }
    }>>(url);

    return stream.pipe(map(response => {
      this.parse(response.data.metadata);
      return response.data.metadata;
    }));
  }

  public post(companyId: number, entity: string, entityId: string | number, data: any): Observable<{ [key: string]: MetaDataField }> {
    const url = this.baseUrl
      .replace(":companyId", companyId.toString())
      .replace(":entity", entity)
      .replace(":entityId", entityId.toString());

    const stream = this.http.post<JSend<{
      metadata: { [key: string]: MetaDataField }
    }>>(url, data);

    return stream.pipe(map(response => {
      this.parse(response.data.metadata);
      return response.data.metadata;
    }));
  }

  public delete(companyId: number, entity: string, entityId: string | number): Observable<any> {
    const url = this.baseUrl
      .replace(":companyId", companyId.toString())
      .replace(":entity", entity)
      .replace(":entityId", entityId.toString());

    return this.http.delete<any>(url);
  }

  private parse(data: { [key: string]: MetaDataField }): void {
    if (data) {
      for (const key in data) {
        const field = data[key];
        if (field.input) {
          switch (field.input.type) {
            case 'date':
              field.value = new Date(field.value);
              break;
          }
        }
      }
    }
  }
}

class inputRender {
  type: 'number' | 'text' | 'date';
  placeholder?: string | number;
  step?: number;
  maxlength?: number;
  min?: number;
  max?: number;
  percentPipe?: string;
  decimalPipe?: string;
}

export class MetaDataField {
  label: string;
  important?: boolean;
  input: inputRender;
  addon?: {
    right?: string;
    left?: string;
  }

  value?: any;
  order?: number;
}