import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { PaymentCondition } from '../../../models/payment-condition.model';
import { PaymentDetail } from '../../../models/payment-detail.model';
import { CurrentDateService } from '../../../services/current-date.service';
import { OrderService } from '../../modules/commercial/services/order.service';

@Component({
  selector: 'payment-condition-input',
  templateUrl: './payment-condition-input.component.html',
  styleUrls: ['./payment-condition-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PaymentConditionInputComponent),
    multi: true,
  }]
})
export class PaymentConditionInputComponent implements OnDestroy, ControlValueAccessor {

  @ViewChild('paymentDetail', { static: true }) private readonly template: TemplateRef<any>;

  @Input() public disabled: boolean;

  public description: string;
  public modalRef: BsModalRef;
  public minPaymentDate = new Date(this.currentDate.get().getTime() + 24 * 60 * 60 * 1000);
  public paymentConditions: PaymentCondition[] = [];
  public selection: PaymentDetail;

  private innerValue: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private orderService: OrderService,
    private currentDate: CurrentDateService,
    private modalService: BsModalService
  ) {
    this.subscriptions.push(this.orderService.getPaymentConditions().subscribe(
      paymentConditions => {
        this.paymentConditions = paymentConditions;
      }
    ));
  }

  get value(): any {
    return this.innerValue;
  };

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_) => void = () => { };

  set value(v) {
    if (v !== this.innerValue) {
      this.innerValue = v;

      this.updateDescription();
      this.onChangeCallback(v);
    }
  }

  updateDescription(): void {
    let datePipe = new DatePipe('es-AR');
    let v = this.value;

    if (v && v.payment_condition && v.payment_condition.id === 3) {

      this.description = v.other;

    } else if (v && v.payment_condition) {

      this.description = v.payment_condition.name +
        (v.payment_condition.id === 2 ? ' (' + datePipe.transform(v.date, "dd/MM/yy") + ')' : '');

    }

  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(value) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.updateSelection();
      this.updateDescription();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  updateSelection(): void {
    this.selection = new PaymentDetail();
    if (this.value) {
      this.selection.payment_condition = this.value.payment_condition;
      this.selection.date = this.value.date;
      this.selection.other = this.value.other;
    }
  };

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  changePaymentCondition(payment_condition: PaymentCondition) {
    this.selection.date = null;
    if (this.selection.other)
      this.selection.other = undefined;
    this.selection.payment_condition = payment_condition;
  }

  showModal(): void {
    if (!this.disabled) {
      this.updateSelection();
      this.modalRef = this.modalService.show(this.template, {});
    }
  }

  accept(): void {
    this.value = this.selection;
    this.modalRef.hide();
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
