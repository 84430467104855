<ol class="contract" #contractContainer
[class.no-select]="refreshInProgress">
  <ng-container *ngIf="contract_clone">
    <ng-container *ngFor="let groupOfClauses of contract; let i = index">
      <ng-container *ngFor="let clause of groupOfClauses.clauses | pairs; let j = index">
        <negotiable-group
        [clause]="clause"
        [formMode]="formMode"
        [direction]="direction"
        [negotiableMode]="negotiableMode" [createOrder]="createOrder"
        [negotiableValue]="negotiability?.contract_detail && negotiability?.contract_detail[clause.id]"
        [hidden]="checkIfClauseWasDeleted(clause)" [isNew]="new_clauses.includes(clause.id)"
        (onDiscardChanges)="resetClause($event, true)">

          <div ngModelGroup="clauseGroup-{{ clause.id }}">
            <ng-container *ngIf="!clause.editable">
              <a *ngIf="clause.removable && formMode && !clause.delete &&
              !(negotiability?.contract_detail && negotiability?.contract_detail[clause.id])"
              class="pull-right"
              title="{{ 'PROPOSAL.CONTRACT.CLAUSE.DELETE.LABEL'|translate }}"
              (onConfirm)="deleteClause(clause.id)"
              [confirm-options]="{
                title: 'PROPOSAL.CONTRACT.CLAUSE.DELETE.TITLE'|translate,
                body: '',
                action: 'GLOBAL.YES_DELETE'|translate
              }"><i class="material-symbols-rounded">delete</i></a>
  
              <b
              [class.skeleton-box]="refreshInProgress">{{ contract_clone[i].clauses[j].title }}</b>
              <p [innerHTML]="contract_clone[i].clauses[j].text | replaceWildcards: wildcards"
              [class.skeleton-box]="refreshInProgress"></p>
            </ng-container>

            <ng-container *ngIf="clause.editable">          
              <small *ngIf="negotiableMode === 'editable' && !clause.delete">
                <on-off-switch class="small pull-right" [ngModelOptions]="{ standalone: true }"
                  [ngModel]="negotiability?.contract_detail && negotiability?.contract_detail[clause.id]" (ngModelChange)="negotiability?.contract_detail && (negotiability?.contract_detail[clause.id] = $event)"></on-off-switch>
              </small>
    
              <a
              *ngIf="clause.removable && formMode && !clause.delete &&
              !(negotiability?.contract_detail && negotiability?.contract_detail[clause.id])"
              class="pull-right"
              title="{{ 'PROPOSAL.CONTRACT.CLAUSE.DELETE.LABEL'|translate }}"
              (onConfirm)="deleteClause(clause.id)"
              [confirm-options]="{
                title: 'PROPOSAL.CONTRACT.CLAUSE.DELETE.TITLE'|translate,
                body: '',
                action: 'GLOBAL.YES_DELETE'|translate
              }"><i class="material-symbols-rounded">delete</i></a>
  
              <a [class.hidden]="editing || (negotiability?.contract_detail && negotiability?.contract_detail[clause.id])"
              *ngIf="formMode && !createOrder && !clause.delete" class="pull-right"
              (click)="enableEditor(clause)" title="{{ 'PROPOSAL.CONTRACT.CLAUSE.EDIT'|translate }}">
                <i class="material-symbols-rounded">edit</i>
              </a>
    
              <b>{{ clause.title }}</b>
    
              <p
              [class.hidden]="editing?.id === clause.id && !createOrder || (!clause.delete && createOrder)"
              semanticDiff [left]="clausesBuffer[clause.id]?.left" [right]="clausesBuffer[clause.id]?.right"></p>
      
              <div [class.has-error]="!clause.text && emptyClause"
              [class.hidden]="editing?.id !== clause.id && !createOrder || clause.delete">
                <textarea id="my-editor-{{ clause.id }}" class="form-control"
                required
                (blur)="doneEditing()" name="clause-{{ clause.id }}"
                (focus)="editing = clause"
                placeholder="{{ 'PROPOSAL.CONTRACT.CLAUSE.PLACEHOLDER'|translate }}" rows="4" trackedControl
                [compareWith]="compareString" [(ngModel)]="clause.text"
                maxlength="4096"
                [previousValue]="(previousContract?.length) ? getPreviousClauseById(clause.id)?.text : null">{{ clause.text }}</textarea>
                <div *ngIf="!clause.text && emptyClause" class="help-block small">{{ 'PROPOSAL.CONTRACT.CLAUSE.EMPTY'|translate }}</div>
              </div>
            </ng-container>
          </div>
        </negotiable-group>
      </ng-container>
    </ng-container>  

    <button *ngIf="formMode" [disabled]="editing || refreshInProgress" type="button" title="{{ 'PROPOSAL.CONTRACT.CLAUSE.ADD'|translate }}"
    class="btn btn-link btn-block" (click)="clauseTitleInput.showModal()">
      <i class="material-symbols-rounded">add</i>
      {{ 'PROPOSAL.CONTRACT.CLAUSE.ADD'|translate }}
    </button>
  </ng-container>

  <div class="loading" *ngIf="!contract_clone">
    <spinner message="{{ 'CONTRACTS.PREPARING'|translate }}"></spinner>
  </div>
</ol>

<clause-title-input #clauseTitleInput="clauseTitleInput" (onInputTitle)="addClause($event)"></clause-title-input>