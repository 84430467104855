import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Slot, Stop } from '../../../../../models/slot.model';

@Component({
  selector: 'ag-stop-status',
  templateUrl: './stop-status.component.html',
  styleUrls: ['./stop-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopStatusComponent {

  @Input()
  set slot(value: Slot) {
    this._slot = value;
    this.details = this.calculateDetails();
    this.class = this.calculateStopClass();
    this.hasTooltip = this.calculateTooltip();
  }

  get slot(): Slot {
    return this._slot;
  }

  public details: { label: string; date: Date }[] = [];
  public class: string = '';
  public hasTooltip: boolean = false;

  public get stop(): Stop {
    return this.slot.stop;
  }

  private _slot: Slot;

  private calculateDetails(): { label: string; date: Date }[] {
    return [
      { label: 'IMPORTER.UPDATED', date: this.stop?.updated_at },
      { label: 'SLOTS.STATUS.STOP.2', date: this.stop?.activated_at },
      { label: 'SLOTS.STATUS.STOP.5', date: this.stop?.arrived_at },
      { label: 'SLOTS.STATUS.STOP.3', date: this.stop?.unloaded_at },
    ];
  }

  private calculateStopClass(): string {
    if (!this.stop) {
      return '';
    }

    const unloaded = this._slot?.unloaded;
    if (unloaded) this.stop.status.id = 3;

    const { id } = this.stop.status;

    if (id === 1) return 'label-danger';
    else if (id === 2) return 'label-warning';
    else if (id === 3) return 'label-success';
    else if (id === 5) return 'label-info';
    else return '';
  }

  private calculateTooltip(): boolean {
    return !!this.stop?.updated_at ||
      !!this.stop?.activated_at ||
      !!this.stop?.arrived_at ||
      !!this.stop?.unloaded_at;
  }
}
