import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { buildFilters } from '../../../../utilities/filters';
import { Unload } from '../models/unload.model';

/** [[Unload]] service. */
@Injectable({
  providedIn: 'root'
})
export class UnloadService {

  private baseUrl: string = '/:apiBase/companies/:companyId/unloads';

  /**
   * Maps only those parameters that don't match in the API call.
   * Format - 'Webapp query': 'API query'
   */
  private readonly queryMap: Record<string, string> = {
    'product_id': 'filters[product_id]',
    'warnings': 'filters[warnings]'
  };

  constructor(
    private http: HttpClient
  ) { }

  public get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: Unload[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.baseUrl.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<Unload[]>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(Unload, response.body), headers: response.headers };
    }));
  }
}
