import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { buildFilters } from '../../../../utilities/filters';
import { Waybill } from '../models/waybill.model';

/** [[Waybill]] service. */
@Injectable({
  providedIn: 'root'
})
export class WaybillService {

  private baseUrl: string = '/:apiBase/companies/:companyId/waybills';
  private scanBarcodesUrl: string = '/:apiBase/barcodes/scan';
  private waybillById: string = this.baseUrl + '/:waybillId';
  private waybillFiles: string = this.waybillById + '/files';
  private waybillFileId: string = this.waybillFiles + '/:fileId';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>; // = {
  // 'past_range': 'filters[range]'
  // };

  /** @ignore */
  constructor(
    private http: HttpClient
  ) { }

  /** @deprecated */
  public scanBarcodes(file: any): Observable<any> {
    // Generates Form Data
    const form = new FormData();
    form.append('file', file);
    form.append('pages', "1");

    const url = this.scanBarcodesUrl;

    return this.http.post<any>(url, form);
  }

  /** Creates a new [[Waybill]] and fill in the specified PDF. */
  public create(companyId: number, waybill: Waybill, slotId: number, file: any): Observable<any> {
    // Generates Form Data
    const form = new FormData();
    form.append('file', file);
    form.append('waybill', JSON.stringify(waybill));
    form.append('slot_id', String(slotId));

    const url = this.baseUrl.replace(':companyId', companyId.toString());

    return this.http.post<any>(url, form);
  }

  /** Updates a [[Waybill]] and fill the specified PDF. */
  public update(waybillId: number, companyId: number, waybill: Waybill, slotId: number, file: any): Observable<any> {
    // Generates Form Data
    const form = new FormData();
    form.append('file', file);
    form.append('waybill', JSON.stringify(waybill));
    form.append('slot_id', String(slotId));

    const url = this.waybillById
      .replace(':companyId', companyId.toString())
      .replace(':waybillId', waybillId.toString());

    return this.http.post<any>(url, form);
  }

  public get(companyId: number, filters?: any, paginated?: boolean): Observable<{ body: Waybill[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.baseUrl.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<Waybill[]>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(Waybill, response.body), headers: response.headers };
    }));
  }

  /** Search and return the [[Waybill]] according to ID. */
  public getById(companyId: number, waybillId: number): Observable<Waybill> {
    const url = this.waybillById.replace(':companyId', companyId.toString()).replace(':waybillId', waybillId.toString());

    let stream = this.http.get<Waybill>(url);

    return stream.pipe(map(waybill => {
      return plainToInstance(Waybill, waybill);
    }));
  }

  public getFiles(companyId: number, waybillId: number): Observable<any> {
    const url = this.waybillFiles
      .replace(':companyId', companyId.toString())
      .replace(':waybillId', waybillId.toString());

    return this.http.get<any>(url);
  }

  public getFile(companyId: number, waybillId: number, fileId: number): Observable<any> {
    const url = this.waybillFileId
      .replace(':companyId', companyId.toString())
      .replace(':waybillId', waybillId.toString())
      .replace(':fileId', fileId.toString());

    return this.http.get<any>(url);
  }
}
