import { Transform } from "class-transformer";

/**
 * Transformador para convertir fechas UTC a GMT-3.
 */
export function TransformToGMT3() {
  return Transform(({ value }) => {
    if (!value) return value; // Si el valor es null o undefined, no hacer nada.

    let date: Date;

    if (value instanceof Date) {
      date = value; // Si ya es un objeto Date, lo usamos directamente.
    } else if (typeof value === "string" && !isNaN(Date.parse(value))) {
      date = new Date(value); // Si es un string válido en formato ISO, lo convertimos a Date.
    } else {
      return value; // Si no es ni Date ni un string de fecha, devolvemos el valor tal cual.
    }

    // Ajusta la fecha de UTC a GMT-3
    const offsetMs = -3 * 60 * 60 * 1000; // GMT-3 en milisegundos
    return new Date(date.getTime() + offsetMs);
  });
}
