import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { instanceToInstance } from 'class-transformer';

import { Company } from '../../../../../models/company.model';
import { Currency } from '../../../../../models/currency.model';
import { Product } from '../../../../../models/product.model';
import { Unit } from '../../../../../models/unit.model';
import { CompanyService } from '../../../../../services/company.service';
import { CurrentDateService } from '../../../../../services/current-date.service';
import { HubSpotService } from '../../../../../services/hub-spot.service';
import { MarketService } from '../../../../../services/market.service';
import { startOfDay } from '../../../../../utilities/date';
import { genCropList } from '../../../../../utilities/setProp';
import { Contract } from '../../models/contract.model';
import { ContractService } from '../../services/contract.service';

@Component({
  selector: 'ag-add-contract-modal',
  templateUrl: './add-contract-modal.component.html',
  styleUrls: ['./add-contract-modal.component.css']
})
export class AddContractModalComponent implements OnInit, OnDestroy {

  @ViewChild('modalTemplate', { static: true }) private readonly modalTemplate: TemplateRef<any>;

  @Input() public company: Company;

  @Output() readonly save = new EventEmitter();

  public contract: Contract;
  /** List of crop years. */
  public cropList: string[];
  /** [[Market]] supported [[Currency|Currencies]]. */
  public currencies: Currency[] = [];
  public disableSelectizeFor = {
    buyer: false,
    seller: false,
    broker: false
  };
  public editMode: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public noResultsFor = {
    buyer: false,
    seller: false,
    broker: false
  };
  public processing: boolean;
  public products: Product[] = [];
  public today: Date;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    /** @ignore */
    public companyService: CompanyService,
    private contractService: ContractService,
    private currentDate: CurrentDateService,
    private modalService: BsModalService,
    public hubSpotService: HubSpotService,
    private marketService: MarketService
  ) {
    this.today = this.currentDate.get();
  }

  /** @ignore */
  ngOnInit(): void {
    this.subscriptions.push(this.marketService.watchProducts().subscribe(response => {
      if (response) this.products = response.sort((a, b) => a.name.localeCompare(b.name)); // Sorts alphabetically
    }));
    this.subscriptions.push(this.marketService.watchCurrencies().subscribe(response => {
      this.currencies = response;
    }));
    this.cropList = genCropList();
  }

  public open(contract?: Contract): void {
    if (this.company) {
      this.contract = contract ? instanceToInstance(contract) : this.defaultContract();
      this.openModal(this.modalTemplate, 'modal-lg');

      this.editMode = this.contract.reference ? true : false;

      // Product selection workaround
      // Product object is retrieved with minimal data, this populates the attribute with the complete object
      if (this.contract.product) {
        this.contract.product = this.products.filter(product => product.id === this.contract.product.id)[0];
        this.changeProduct();
      }
    }
  }

  private defaultContract(): Contract {
    let contract: Contract = new Contract();

    contract.date = this.currentDate.get();

    if (!contract.brokers) contract.brokers = [];

    if (this.company.activity.broker) {
      contract.brokers[0] = this.company;
      this.disableSelectizeFor.broker = true;
    }

    if (this.company.activity.seller &&
      !this.company.activity.buyer &&
      !this.company.activity.broker) {
      contract.seller = this.company;
      this.disableSelectizeFor.seller = true;
    }

    contract.price.unit = this.currencies[0];

    return contract;
  }

  private isValidUnit(unit: Unit): boolean {
    let search: Unit;

    if (unit) search = this.contract.product.quantity_units.find(u => unit.id === u.id);

    return search ? true : false;
  }

  public changeProduct(): void {
    if (this.contract.product) {
      const default_unit = this.contract.product.quantity_units[0];
      if (!this.isValidUnit(this.contract.price.quantity_unit)) this.contract.price.quantity_unit = default_unit;
      if (!this.isValidUnit(this.contract.quantity.unit)) this.contract.quantity.unit = default_unit;
    }
  }

  public setDeliveryRange(days: number = 45): void {
    // Sets delivery range, from today to 'days' days after
    // Default value 45
    const today_start = startOfDay(this.today);

    this.contract.delivery.range = [
      today_start,
      new Date(today_start.getTime() + (days * 24 * 60 * 60 * 1000))
    ];
  }

  private checkSelectizeEmptyResponse(e): number {
    if (e.query === "") {
      // With an empty query
      if (e.results.length === 1) {
        // Only one result
        return 1;
      } else if (e.results.length === 0) {
        // No results
        return 0;
      }
    }
    return -1;
  }

  public companyTypeLoad(type: string, e, assignIfOne: boolean = true): void {
    const result = this.checkSelectizeEmptyResponse(e);
    if (result === 1 && assignIfOne) {
      this.contract[type] = e.results[0];
      this.disableSelectizeFor[type] = true;
    } else if (result === 0) {
      this.noResultsFor[type] = true;
    }
  }

  public submit(): void {
    this.processing = true;

    const stream = this.editMode ? this.contractService.update(this.company.id, this.contract) : this.contractService.create(this.company.id, this.contract);

    this.subscriptions.push(stream.subscribe(response => {
      this.closeModal(() => {
        this.save.emit();
      });
    }));
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: any; }, b: { id: any; }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
