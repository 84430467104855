import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Assignee } from '../../models/assignee.model';
import { JSend } from '../../models/jsend.model';

@Injectable({
  providedIn: 'root'
})
export class AssigneesService {

  private baseUrl: string = '/:apiBase/assignees';
  private assigneeById: string = this.baseUrl + '/:assigneeId';

  constructor(
    private http: HttpClient
  ) { }

  /** Create a new [[Assignee]]. */
  public create(companyId: number, key: string, id: number | string, assignee: Assignee): Observable<string> {
    return this.http.post<JSend<{
      id: string
    }>>(this.baseUrl, {
      companyId: companyId,
      userId: assignee.userId,
      accountId: assignee.accountId,
      entityKey: key,
      entityId: id.toString(),
      roleId: assignee.roleId
    }).pipe(map(response => {
      return response.data.id;
    }));
  }

  /**
   * Returns a collection of [[Assignee|Assignees]] of the specified entity and
   * [[Company]].
   */
  public get(companyId: number, key: string, id: number | string): Observable<Assignee[]> {
    return this.http.get<JSend<{
      assignees: Assignee[]
    }>>(this.baseUrl, {
      params: {
        companyId: companyId.toString(),
        entityKey: key,
        entityId: id.toString()
      }
    }).pipe(map(response => {
      return plainToInstance(Assignee, response.data.assignees);
    }));
  }

  /** Deletes the specified [[Assignee]]. */
  public delete(assignee: Assignee): Observable<JSend> {
    const url = this.assigneeById.replace(':assigneeId', assignee.id.toString());

    return this.http.delete<JSend>(url);
  }
}
