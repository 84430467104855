import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Company } from '../../../../../models/company.model';
import { Quantity } from '../../../../../models/quantity.model';
import { CompanyService } from '../../../../../services/company.service';
import { CurrentDateService } from '../../../../../services/current-date.service';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { startOfDay } from '../../../../../utilities/date';
import { SlotRequestStatus, SlotsRequest } from '../../../../models/slots-request.model';
import { SlotRequestService } from '../../../../services/slot-request.service';
import { Contract } from '../../../contracts/models/contract.model';

@Component({
  selector: 'slots-batch-contract-request-form',
  templateUrl: './slots-batch-contract-request-form.component.html',
  styleUrls: ['./slots-batch-contract-request-form.component.scss']
})
export class SlotsBatchContractRequestFormComponent implements OnInit {

  @Input() public company: Company;
  @Input() public contract: Contract;

  @Output() public readonly close: EventEmitter<void> = new EventEmitter<void>();

  /** @ignore */
  public today: Date;
  public processing: boolean = false;
  public formArray: UntypedFormArray = new UntypedFormArray([]);
  public observations: string = '';
  public selectedRecipient: Company;
  public maxCuposToRequest: number;
  public slotsAvailable: number;
  public totalSlotsRequest: number;
  public someControlIsEmpty: boolean;
  public totalRequestedSlotsInContract: number;
  public buyers: Company[];
  public errorMessage?: string = null;

  constructor(
    private slotRequestService: SlotRequestService,
    private currentDate: CurrentDateService,
    public companyService: CompanyService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.today = startOfDay(this.currentDate.get());

    this.addCupo();
    this.subscribeToFormChanges();
    this.getSlotsRequests();
    this.initConfig();
  }

  private initConfig(): void {
    this.selectedRecipient = this.contract.buyer;
    this.buyers = this.getBuyers();
    this.maxCuposToRequest = this.assignableSlotsToRequest();
  }

  get arrayCantCupos(): number[] {
    return Array.from({ length: this.maxCuposToRequest + 1 }, (_, i) => i);
  }

  get appliedVolume(): Quantity {
    if (this.contract.applied) {
      return this.contract.applied;
    }

    return new Quantity({ value: 0 });
  }

  private subscribeToFormChanges(): void {
    this.slotsAvailable = this.assignableSlotsQty();

    this.formArray.valueChanges.subscribe(value => {
      this.totalSlotsRequest = this.sumTotalSlotsRequest();
      this.slotsAvailable = this.assignableSlotsQty();
      this.errorMessage = null;
      this.checkSomeControlIsEmpty();
    });
  }

  private getSlotsRequests(): void {
    this.slotRequestService.get(this.company.id, {
      'filters[contract][id]': this.contract.id,
      validity: 'on_date'
    }).subscribe((slotsRequests) => {
      if (slotsRequests.body?.length) {
        const slotsRequestFiltered = slotsRequests.body.filter(({ status }) => (status.id !== SlotRequestStatus.RETIRED && status.id !== SlotRequestStatus.REJECTED));

        this.contract.slot_request = slotsRequestFiltered;
        this.totalRequestedSlotsInContract = slotsRequestFiltered.reduce((accum, curr) => accum + curr.quantity, 0);
      } else {
        this.contract.slot_request = [];
      }
    })
  }

  public getBuyers(): Company[] {
    const buyers: Company[] = [
      this.contract.buyer,
      ...this.contract.brokers
    ];

    return buyers;
  }

  private assignableSlotsQty(): number {
    const totalAssignableSlots = this.assignableSlotsToRequest();
    return totalAssignableSlots - this.totalSlotsRequest;
  }

  private assignableSlotsToRequest(): number {
    const pendingVolumeToApply = this.calculatePendingVolumeToApply();

    return Math.ceil(pendingVolumeToApply / 30) + 1;
  }

  private calculatePendingVolumeToApply(): number {
    const contractVolume = this.contract.quantity?.value || 0;
    const appliedVolume = this.appliedVolume.value || 0;

    return contractVolume - appliedVolume;
  }

  private sumTotalSlotsRequest(): number {
    return this.formArray.value.reduce((acc, slot) => acc + parseInt(slot.cuposQty), 0);
  }

  /** FormArray Methods */
  public removeCupo(index: number): void {
    if (this.formArray.length === 1) return;

    this.formArray.removeAt(index);
  }

  public clear(): void {
    this.formArray.clear();
  }

  public addCupo(): void {
    const group = new UntypedFormGroup({
      cuposQty: new UntypedFormControl(0),
      date: new UntypedFormControl(new Date())
    });

    this.formArray.push(group);
    this.checkSomeControlIsEmpty();
  }

  private checkSomeControlIsEmpty(): void {
    this.someControlIsEmpty = this.formArray.value.some(slot => slot.cuposQty === 0);
  }

  private formToClass(formArray: UntypedFormArray): SlotsRequest[] {
    const slotsInForm = formArray.value;
    const slotsRequests: SlotsRequest[] = [];

    slotsInForm.forEach(request => {
      if (request.cuposQty === 0) return;

      const slotRequested = new SlotsRequest({
        quantity: request.cuposQty,
        date: request.date,
        observations: this.observations,
        company: this.company,
        recipient: this.selectedRecipient,
        contract: this.contract,
      }
      );

      slotsRequests.push(slotRequested);
    });

    return slotsRequests;
  }

  public submit(): void {
    const slotsRequested = this.formToClass(this.formArray)
    this.processing = true;
    this.slotRequestService.create(this.company.id, slotsRequested).subscribe({
      complete: () => {
        this.processing = false;
        this.close.emit();
      },
      error: error => {
        this.errorMessage = error.message?.ext_invalid_action || error.info?.message;
        this.processing = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    })
  }
}
