import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { Observable } from "rxjs";
import { map, mergeMap, startWith } from 'rxjs/operators';

import { PusherService } from "../../services/pusher.service";
import { buildFilters } from "../../utilities/filters";
import { SlotsRequest } from "./../models/slots-request.model";

@Injectable({
  providedIn: 'root'
})
export class SlotRequestService {

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private baseUrl = '/:apiBase/companies/:companyId';
  private slotRequest = this.baseUrl + '/slots-request';
  private slotRequestReject = this.slotRequest + '/:slotRequestId/reject';
  private slotRequestRetire = this.slotRequest + '/:slotRequestId/retire';
  private slotRequestById = this.slotRequest + '/:slotRequestId';

  /**
   * Maps only those parameters that don't match in the API call.
   * Format - 'Webapp query': 'API query'
   */
  private readonly queryMap: Record<string, string> = {
    'product_id': 'filters[product][id]',
    'validity': 'filters[validity]',
    'negotiation_id': 'filters[negotiation][reference]',
    'recipient_name': 'filters[recipient_name]',
    'assigned_to': 'filters[assigned_to]',
    'sustainable': 'filters[sustainable]'
  };

  public create(companyId: number, data: SlotsRequest[]): Observable<any> {
    const url = this.slotRequest
      .replace(':companyId', companyId.toString());

    return this.http.post(url, data);
  }

  public get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: SlotsRequest[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.slotRequest.replace(':companyId', companyId.toString());

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<SlotsRequest[]>(url, { observe: 'response' });
    return stream.pipe(map(response => {
      return { body: plainToInstance(SlotsRequest, response.body), headers: response.headers };
    }));

  }

  public reject(companyId: number, slotsRequestId: number, observations: string = ""): Observable<SlotsRequest> {
    const url = this.slotRequestReject
      .replace(':companyId', String(companyId))
      .replace(':slotRequestId', String(slotsRequestId))

    return this.http.patch<SlotsRequest>(url, { observations });
  }

  public retire(companyId: number, slotsRequestId: number, observations?: string): Observable<SlotsRequest> {
    const url = this.slotRequestRetire
      .replace(':companyId', String(companyId))
      .replace(':slotRequestId', String(slotsRequestId))

    return this.http.patch<SlotsRequest>(url, { observations });
  }

  public delete(companyId: number, slotsRequestId: number, observations?: string): Observable<any> {
    const url = this.slotRequestById
      .replace(':companyId', String(companyId))
      .replace(':slotRequestId', String(slotsRequestId))

    return this.http.delete(url, { body: observations });
  }

  public watch(companyId: number, userId: number, filters?: any, paginated?: boolean): Observable<{ body: SlotsRequest[], headers: HttpHeaders }> {
    return this.pusherService.listenToMultiple([
      { channel: `company_${companyId}`, event: 'slot_request' },
      { channel: `private_${userId}`, event: 'slot_request' }
    ], 2000).pipe(
      startWith({}),
      // Mapea cada evento a una nueva llamada a `getOrder`
      mergeMap(() => this.get(companyId, filters, paginated))
    );
  }
}