<ag-contracts-slots-modals #slotsModals [company]="company" [user]="user"></ag-contracts-slots-modals>

<ag-view-batch-modal #viewBatchModal [company]="company"></ag-view-batch-modal>

<ng-template #loadingSpinner>
  <div class="loading" *ngIf="processing">
    <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
  </div>
</ng-template>

<ng-container *ngIf="!processing; else loadingSpinner">
  <div class="horizontal-scroller">
    <div class="table-sticky-headers" [class.tableContainer]="compact">
      <table class="table table-condensed" sortable #tableSortable="tableSortable" [variableInUrl]="true">
        <thead>
          <tr class="tr-align-center">
            <th class="sap" sortBy="date">
              {{ "GLOBAL.DATE" | translate }}
            </th>
            <th class="sap" *ngIf="!compact">
              {{ "GLOBAL.CONTRACT" | translate }}
            </th>
            <th>
              {{ "SLOTS.REQUEST_SLOT_MODAL.APPLICANT" | translate }}
              <column-filter 
              *ngIf="!predefinedFilters" 
              type="string" 
              alignment="left" 
              [filters]="filters" 
              key="filters[company.name]"
              (onChange)="changeFilter($event)">
              </column-filter>
            </th>
            <th>
              {{ "SLOTS.REQUEST_SLOT_MODAL.RECIPIENT" | translate}}
            </th>
            <th>
              {{ "CPE.ACTORS.DESTINO" | translate}}
            </th>
            <th *ngIf="!compact">
              {{ "GLOBAL.PRODUCT" | translate}}
            </th>
            <th class="sap" *ngIf="!compact">
              <abbr title="{{ 'GLOBAL.OBSERVATIONS'|translate }}">{{ 'GLOBAL.OBSERVATIONS_ABBR'|translate }}</abbr>
            </th>
            <th class="sap" sortBy="quantity">
              <abbr title="{{ 'GLOBAL.QUANTITY'|translate }}">{{ "GLOBAL.QUANTITY_ABBR" | translate}}</abbr>
            </th>
            <th class="sap" >
              <!-- {{ "SLOTS.TABLE_HEADER.ASSIGNED_SLOTS" | translate }} -->
            </th>
            <th class="sap" *ngIf="!compact">
              {{ "GLOBAL.STATUS" | translate }}
            </th>
            <th class="sap" ></th> 
            <th></th>
          </tr>
        </thead>

        <tbody *ngIf="requests?.length"  class="scrollit">
          <tr class="tr-align-center" *ngFor="let item of requests" [class.expired-request]="item.expired" >
            <td class="sap small">
              <date-md [date]="item.date" [style]="true">
              </date-md>
            </td>
            <td class="sap small" *ngIf="!compact">
              <a *ngIf="item.contract"
                [routerLink]="['/company', company.id, 'contracts', item.contract.id]">
                <samp>#{{ item.contract.reference }}</samp>
              </a>
              <a *ngIf="item.negotiation"
              [routerLink]="['/company', company.id, 'counter-order', item.negotiation.id]">
                <samp>#{{ item.negotiation.id }}</samp>
              </a>
            </td>
            <td>
              <company-info [company]="item.company" [compact]="compact"
              [activity]="false"></company-info>
            </td>
            <td>
              <company-info [company]="item.recipient" [compact]="compact"
              [activity]="false"></company-info>
            </td>
            <td class="small text-muted">
              {{ (item.contract?.delivery?.locations || item.negotiation?.proposal.business_detail.delivery?.locations) | locationString }}
            </td>
            <td *ngIf="!compact">
              {{ item.contract?.product.name || item.negotiation?.order.product.name }}
            </td>
            <td class="sap text-center" *ngIf="!compact">
              <i tooltip="{{ item.observations }}" placement="right" class="text-muted material-symbols-rounded"
                *ngIf="item.observations">comment</i>
            </td>
            <td>
              <samp class="slots snum dlb hidden-xs">{{item.quantity}}</samp>
            </td>
            <td class="sap">
              <progress-circle label="{{ 'SLOTS.ASSIGNED'|translate }}: {{ item.assignedQuantity }}"
                [progress]="item.assignedPercentage">
              </progress-circle>
            </td>
            <!-- Status label -->
            <td class="sap" *ngIf="!compact" [ngClass]="getStatusClass(item.status?.id)" >
              {{ item.status?.locale_name | translate }}
            </td>
            <td class="sap">
              <div *ngIf="item.batches?.length"
              class="btn-group" dropdown
              placement="bottom right">
                <button dropdownToggle type="button" class="btn btn-link dropdown-toggle">
                  <span class="material-symbols-rounded small">local_shipping</span>
                </button>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                  <li *ngFor="let batch of item.batches">
                    <a class="dropdown-item"
                    (click)="viewBatchModal.showBatch(batch)">
                      {{ batch.slots_total }} <span class="material-symbols-rounded faded">arrow_right</span> {{ batch.destination.company.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </td>
            <!-- Status Action buttons -->
            <td class="sap">
              <div class="btn-group">
                <!-- Asignar -->
                <button *ngIf="canAccept(item)" type="button" [disabled]="readonlyUser"
                  (click)="slotsModals.assignBatchToContract(item.contract, item)" class="btn btn-agree">
                  {{ 'SLOTS.ASSIGN'|translate }}
                </button>
                <!-- Rechazar -->
                <button *ngIf="canReject(item)" type="button" [disabled]="readonlyUser" class="btn btn-danger"
                  (onConfirm)="reject($event, item)" [confirm-options]="{
                    title: 'SLOTS.REJECT_REQUEST_MODAL.TITLE'|translate,
                    body: 'SLOTS.REJECT_REQUEST_MODAL.DESCRIPTION'|translate,
                    action: 'GLOBAL.YES_REJECT'|translate,
                    observations: ''}">
                  {{ 'GLOBAL.REJECT'|translate }}
                </button>
                <!-- Retirar -->
                <button *ngIf="canRetire(item)" type="button" class="btn btn-warning" [disabled]="readonlyUser"
                  (onConfirm)="retire($event, item)" [confirm-options]="{
                    title: 'SLOTS.WITHDRAW_REQUEST_MODAL.TITLE'|translate,
                    body: 'SLOTS.WITHDRAW_REQUEST_MODAL.DESCRIPTION'|translate,
                    action: 'GLOBAL.YES_WITHDRAW'|translate,
                    observations: ''}">
                  {{ 'GLOBAL.WITHDRAW'|translate }} <span class="material-symbols-rounded">undo</span>
                </button>
                <!-- Borrar -->
                <button *ngIf="canDelete(item)" type="button" class="btn btn-danger" [disabled]="readonlyUser"
                (onConfirm)="delete($event, item)" [confirm-options]="{
                  title: 'SLOTS.DELETE_REQUEST_MODAL.TITLE'|translate,
                  action: 'GLOBAL.YES_DELETE'|translate,
                  observations: ''}">
                  <span class="material-symbols-rounded">delete</span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>

      </table>
    </div>
    <!-- No data -->
    <div *ngIf="!requests?.length" class="no-data">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'GLOBAL.NO_DATA'|translate }}
    </div>
  </div>
</ng-container>
