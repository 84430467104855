import { Type } from "class-transformer";
import { LeftPadPipe } from 'ngx-pipes';

import { Company } from "../../../../models/company.model";
import { Product } from "../../../../models/product.model";
import { TransformToGMT3 } from "../utilities/transform-to-gmt3";

export class RetiroProductor {
  certificado_coe: any;
  cuit_remitente_comercial_productor: any;
}

export class Place {
  codigo_provincia: number;
  codlocalidad: number;
  cuit: number;
  localidad?: string;
  planta?: number;
  provincia?: string;
}

export class Intervinientes {
  @Type(() => Company)
  remitente_comercial_venta_primaria?: Company;
  @Type(() => Company)
  remitente_comercial_venta_secundaria?: Company;
  @Type(() => Company)
  remitente_comercial_venta_secundaria_2?: Company;
  @Type(() => Company)
  mercado_a_termino?: Company;
  @Type(() => Company)
  corredor_venta_primaria?: Company;
  @Type(() => Company)
  corredor_venta_secundaria?: Company;
  @Type(() => Company)
  representante_entregador?: Company;
  @Type(() => Company)
  representante_recibidor?: Company;
}

export class DatosCarga {
  codigo_grano: number;
  /** YYYY */
  cosecha: number;
  peso_bruto_descarga: number;
  peso_bruto: number;
  peso_tara_descarga: number;
  peso_tara: number;
  product?: any;
}

export class Transporte {
  /** Cupo */
  codigo_turno: string;
  cuit_chofer: number;
  cuit_intermediario_flete: any;
  cuit_pagador_flete: number;
  cuit_transportista: number;
  dominio: string;

  @TransformToGMT3()
  fecha_partida: Date;

  km_recorrer: number;
  mercaderia_fumigada: boolean;
  tarifa_referencia: number;
  tarifa: number;
}

export class CartaPorteElectronica {
  /** Agree's internal unique identifier. */
  readonly id: number;

  /**
   * Source channel used to import.
   *
   * | ID | Channel        |
   * |---:|----------------|
   * |  1 | API            |
   * |  2 | Imported files |
   * |  3 | UI             |
   * |  4 | AFIP WS        |
   * |  5 | File           |
   */
  readonly import_channel_id: number;

  /** Cmpany ID */
  importer_id: number;

  tipo_carta_porte: number;
  sucursal: number;
  nro_orden: number;
  planta: any;
  /**
   * Numerical code issued by the Argentine state that must be consigned in
   * each Waybill that covers the transfer of grains. It is obtained prior to
   * transportation and must be linked to a valid Waybill.
   *
   * Maximum characters: 12.
   */
  numero_ctg?: number;

  estado: string;

  @TransformToGMT3()
  created_at: Date;

  @TransformToGMT3()
  fecha_emision: Date;

  /** Fecha Descarga */
  @TransformToGMT3()
  fecha_inicio_estado: Date;

  /** Fecha Arribo */
  @TransformToGMT3()
  fecha_vencimiento: Date;

  observaciones?: string;

  @Type(() => Place)
  origen: Place;

  corresponde_retiro_productor: boolean;

  retiro_productor: RetiroProductor;

  @Type(() => Intervinientes)
  intervinientes: Intervinientes;

  // @Type(() => DatosCarga)
  datos_carga: DatosCarga;

  @Type(() => Place)
  destino: Place;

  cuit_destinatario: number;

  @Type(() => Transporte)
  transporte: Transporte;

  file_url?: string;

  product: Product;

  /** Used for sorting purposes */
  get numero(): string {
    const lpad = new LeftPadPipe();
    const sucursal = lpad.transform(String(this.sucursal), 5, '0');
    const nro_orden = lpad.transform(String(this.nro_orden), 8, '0');

    return sucursal + '-' + nro_orden;
  }

  constructor(data: Partial<CartaPorteElectronica> = {}) {
    Object.assign(this, data);
  }
}