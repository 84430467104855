import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, map } from 'rxjs';

import { JSend } from '../../../../models/jsend.model';
import { buildFilters } from '../../../../utilities/filters';
import { CreditLine } from '../models/credit-line.model';

@Injectable({
  providedIn: 'root'
})
export class LinesService {

  private baseUrl: string = '/:apiBase/fintech';
  private companyUrl: string = this.baseUrl + '/companies/:companyId';
  private fundersUrl: string = this.companyUrl + '/funders';
  private funderById: string = this.fundersUrl + '/:funderId';
  private funderLines: string = this.funderById + '/credit-lines';
  private funderExceptions: string = this.funderLines + '/exceptions';
  private funderExceptionsById: string = this.funderExceptions + '/:exceptionId';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>;

  constructor(
    private http: HttpClient
  ) { }

  public get(companyId: number, funderId: string, filters?: any, paginated: boolean = true): Observable<{ body: CreditLine[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.funderExceptions
      .replace(":companyId", companyId.toString())
      .replace(":funderId", funderId);

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<JSend<{ exceptions: CreditLine[] }>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      if (response.body.status === 'success') return { body: plainToInstance(CreditLine, response.body.data.exceptions), headers: response.headers };
      else throw new Error(String(response.body.data));
    }));
  }

  public post(companyId: number, funderId: string, lines: CreditLine[]): Observable<CreditLine[]> {
    const url = this.funderExceptions
      .replace(":companyId", companyId.toString())
      .replace(":funderId", funderId);

    return this.http.post<JSend<{ exceptions: CreditLine[] }>>(url, lines).pipe(map(response => {
      return plainToInstance(CreditLine, response.data.exceptions);
    }));
  }

  public delete(companyId: number, funderId: string, exceptionId?: string): Observable<any> {
    const url = (exceptionId ? this.funderExceptionsById : this.funderExceptions)
      .replace(":companyId", companyId.toString())
      .replace(":funderId", funderId)
      .replace(":exceptionId", exceptionId);

    return this.http.delete<JSend>(url);
  }
}
