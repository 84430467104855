import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

import { BarterProposal } from '../models/barter-proposal.model';
import { Barter } from '../models/barter.model';
import { Company } from '../models/company.model';
import { buildFilters } from '../utilities/filters';
import { PusherService } from './pusher.service';

@Injectable()
export class BarterService {

  private readonly baseUrl = '/:apiBase/companies/:companyId';
  private readonly barterProposalsUrl = this.baseUrl + '/barter-proposals';
  private readonly barterProposalById = this.barterProposalsUrl + '/:barterProposalId';
  private readonly bartersUrl = this.baseUrl + '/barters';
  private readonly bartersById = this.bartersUrl + '/:barterId';

  /**
   * Maps only those parameters that don't match in the API call.
   * Format - 'Webapp query': 'API query'
   */
  private readonly queryMap: Record<string, string> = {
    'status_barter_proposals': 'filters[status_barter_proposals]',
    'commercial_zone': 'filters[commercial_zone]'
  };

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  public createProposal(companyId: number, barter_proposal: BarterProposal, recipients: Company[]): Observable<BarterProposal> {
    const url = this.barterProposalsUrl.replace(":companyId", companyId.toString());
    const data = {
      barter_proposal: barter_proposal,
      recipients: recipients
    };

    return this.http.post<BarterProposal>(url, data);
  }

  private getProposals(companyId: number, filters?: any, paginated?: boolean): Observable<{ body: BarterProposal[]; headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.barterProposalsUrl.replace(":companyId", companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<BarterProposal[]>(url, { observe: 'response' });

    return stream.pipe(map(response => ({ body: plainToInstance(BarterProposal, response.body), headers: response.headers })));
  }

  public watchProposals(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: BarterProposal[]; headers: HttpHeaders }> {
    return this.pusherService.listen(`company_${companyId}`, 'negotiation').pipe(
      startWith({}),
      mergeMap(response => this.getProposals(companyId, filters, paginated))
    );
  }

  public withdrawProposal(companyId: number, barterProposalId: number): Observable<BarterProposal> {
    const url = this.barterProposalById
      .replace(":companyId", companyId.toString())
      .replace(":barterProposalId", barterProposalId.toString());

    return this.http.delete<BarterProposal>(url);
  }

  public create(companyId: number, barter_proposal: BarterProposal, recipients: Company[]): Observable<Barter> {
    const url = this.bartersUrl.replace(":companyId", companyId.toString());
    const data = {
      barter_proposal: barter_proposal,
      recipients: recipients
    }
    const stream = this.http.post<Barter>(url, data);

    return stream.pipe(map(barter => plainToInstance(Barter, barter)));
  }

  public watch(companyId: number, barterId: number): Observable<BarterProposal> {
    return this.pusherService.listen(`company_${companyId}`, 'negotiation').pipe(
      startWith({}),
      mergeMap(response => this.get(companyId, barterId))
    );
  }

  public get(companyId: number, barterId: number): Observable<BarterProposal> {
    const url = this.bartersById
      .replace(":companyId", companyId.toString())
      .replace(":barterId", barterId.toString());
    const stream = this.http.get<BarterProposal>(url);

    return stream.pipe(map(barter_proposal => plainToInstance(BarterProposal, barter_proposal)));
  }

  public edit(companyId: number, barter_proposal: BarterProposal): Observable<BarterProposal> {
    const url = this.bartersById
      .replace(":companyId", companyId.toString())
      .replace(":barterId", barter_proposal.barters[0].id.toString());
    const stream = this.http.put<BarterProposal>(url, barter_proposal);

    return stream.pipe(map(barter_proposal => plainToInstance(BarterProposal, barter_proposal)));
  }

  public send(companyId: number, barter: Barter): Observable<Barter> {
    const url = this.bartersUrl.replace(":companyId", companyId.toString());
    const data = {
      barter: barter
    }
    const stream = this.http.put<Barter>(url, data);

    return stream.pipe(map(barter => plainToInstance(Barter, barter)));
  }

  public delete(companyId: number, barterId: number): Observable<BarterProposal> {
    const url = this.bartersById
      .replace(":companyId", companyId.toString())
      .replace(":barterId", barterId.toString());

    return this.http.delete<BarterProposal>(url);
  }

  public accept(companyId: number, barter: Barter): Observable<Barter> {
    const url = this.bartersUrl.replace(":companyId", companyId.toString());
    // hago la copia para que no me bindee el cambio de status y me lo muestre en el template
    const barter_copy = instanceToInstance(barter);

    barter_copy.orders.map(order => {
      order.negotiations[0].status = { id: 7, name: null };
    });

    return this.http.put<Barter>(url, {
      barter: barter_copy
    });
  }
}
