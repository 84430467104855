import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Observable, Subscription, take } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { Company } from '../../../../../models/company.model';
import { FiscalId } from '../../../../../models/fiscal-id.model';
import { Price } from '../../../../../models/price.model';
import { CompanyService } from '../../../../../services/company.service';
import { CurrentDateService } from '../../../../../services/current-date.service';
import { multiSort } from '../../../../../utilities/array';
import { CreditLine } from '../../models/credit-line.model';
import { FintechProduct } from '../../models/fintech-product.model';
import { Funder } from '../../models/funder.model';
import { FundersService } from '../../services/funders.service';
import { LinesService } from '../../services/lines.service';

@Component({
  selector: 'ag-funder-line-modal',
  templateUrl: './funder-line-modal.component.html',
  styleUrls: ['./funder-line-modal.component.scss']
})
export class FunderLineModalComponent implements OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public funder: Funder;
  @Input() public readonly: boolean;

  @Output('submitted') submitted = new EventEmitter<string>();

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public searchingCompany: boolean;
  public companyLine: Company;
  public lines: CreditLine[];
  public today: Date;
  public fiscalId: string;

  private edited: boolean;
  private products: FintechProduct[];
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private currentDate: CurrentDateService,
    public companyService: CompanyService,
    private linesService: LinesService,
    private fundersService: FundersService,
    private modalService: BsModalService
  ) { }

  public open(fiscalId: string): void {
    this.edited = false;
    this.today = this.currentDate.get();
    this.reset();
    this.fiscalId = fiscalId;

    this.searchCompanyByFiscalId(fiscalId, (company) => {
      if (!company) {
        this.companyLine = new Company({
          id: -1,
          fiscal_id: new FiscalId({ ...this.company.fiscal_id, value: fiscalId }),
          name: 'N/D'
        });
      } else this.companyLine = company;
    });
    this.loadData();

    this.openModal(this.modal);
  }

  public setCompany(company: Company): void {
    this.companyLine = company;
    this.fiscalId = this.companyLine.fiscal_id.value;

    this.loadData();
  }

  public submit() {
    this.processing = true;

    const withValues = this.lines.filter(line => line.amount.value > 0);

    this.subscriptions.push(this.linesService.post(this.company.id, this.funder.id, withValues).subscribe(lines => {
      this.lines = lines;
      this.populateProducts();

      this.processing = false;
      this.edited = true;
    }));
  }

  /**
   * Deletes a specific credit line.
   * Sets `processing` flag to true while the deletion is in progress.
   * Reloads data upon successful deletion.
   * 
   * @param {CreditLine} line - The credit line to be deleted.
   */
  public delete(line: CreditLine): void {
    this.processing = true;

    this.subscriptions.push(this.linesService.delete(this.company.id, this.funder.id, line.id).subscribe(response => {
      this.processing = false;
      this.loadData(); // Reload data after deletion
      this.edited = true;
    }));
  }

  private loadData(): void {
    this.processing = true;

    const observables: Observable<any>[] = [this.linesService.get(this.company.id, this.funder.id, { fiscal_id: 'is:' + this.fiscalId }, false)];
    if (!this.products && !this.readonly) observables.push(this.fundersService.getProducts(this.company.id, this.funder.id, { active: true }, false));

    this.subscriptions.push(combineLatest(observables).pipe(take(1)).subscribe(response => {
      // Destructures the values ​​received from the observables, for readability
      const [
        lines,
        products
      ] = response;

      this.lines = lines.body;
      if (products) this.products = multiSort(
        products.body.filter((product: FintechProduct) => product.approval == 'AUTO'),
        'name'
      );

      this.populateProducts();

      this.processing = false;
    }));
  }

  /**
   * Fills the credit lines with products, ensuring that each product has a corresponding line.
   * Adds a global line if none exists.
   */
  private populateProducts(): void {
    if (!this.readonly) {
      const hasGlobalLine = this.lines.some(line => !line.product);

      // Add missing product lines to `this.lines`
      this.products.forEach(product => {
        const lineExists = this.lines.some(line => line.product?.id === product.id);

        if (!lineExists) {
          this.lines.push(this.createNewLine(product));
        }
      });

      // Ensure there is a global line if none exists
      if (!hasGlobalLine) {
        this.lines.push(this.createNewLine());
      }
    }

    // Sort lines by product name
    this.lines = multiSort(this.lines, 'product.name');
  }

  /**
   * Creates a new credit line, either with a specified product or as a global line if no product is provided.
   * 
   * @param {FintechProduct} [product] - Optional product to associate with the credit line.
   * @returns {CreditLine} The new credit line.
   */
  private createNewLine(product?: FintechProduct): CreditLine {
    return new CreditLine({
      id: uuidv4(),
      fiscal_id: this.fiscalId,
      amount: new Price({
        unit: this.funder.currency,
        value: product ? 0 : undefined
      }),
      product: product
    });
  }

  /**
   * Updates the `amount` of each credit line to match the maximum value of the first line, if `first` is true.
   * This prevents any line amount from exceeding the first line's value.
   * 
   * @param {boolean} first - Indicates whether the function should proceed with updating values.
   */
  public updateLine(first: boolean): void {
    if (!first) return;

    const maxAmount = this.lines[0]?.amount.value;

    this.lines.forEach((line, index) => {
      if (index > 0 && line.amount.value > maxAmount) {
        line.amount.value = maxAmount;
      }
    });
  }

  public reset(): void {
    this.lines = undefined;
    this.companyLine = undefined;
    this.fiscalId = undefined;
  }

  /**
   * Searches for a company by fiscal ID. If the fiscal ID is found in cache, returns the cached company.
   * Otherwise, it makes an API call to retrieve the company data and updates the cache.
   * 
   * @param {string} fiscal_id - The fiscal ID to search for.
   * @param {Function} callBack - Callback function to handle the retrieved company data.
   * @returns {void}
   */
  private searchCompanyByFiscalId(fiscal_id: string, callBack: (company?: Company) => void): void {
    // Proceed with search if fiscal ID is provided
    if (fiscal_id) {
      this.searchingCompany = true;

      this.subscriptions.push(this.companyService
        .getCompaniesByFiscalValue(this.company.id, this.company.market.country_id, fiscal_id)
        .subscribe(companies => {
          this.searchingCompany = false;

          callBack(companies[0]);
        }));
    } else {
      // Invalid fiscal_id, return undefined
      callBack(undefined);
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
      if (this.edited) this.submitted.emit(this.fiscalId);
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
