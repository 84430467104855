import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { plainToInstance } from 'class-transformer';
import { Observable, forkJoin } from 'rxjs';

import { BarterProposal, BarterProposalInvoice } from '../../../../../models/barter-proposal.model';
import { Company, companyCan, companyIs } from '../../../../../models/company.model';
import { Price } from '../../../../../models/price.model';
import { Product } from '../../../../../models/product.model';
import { BarterService } from '../../../../../services/barter.service';
import { CompanyService } from '../../../../../services/company.service';
import { ComponentCommService } from '../../../../../services/component-comm.service';
import { LocationService } from '../../../../../services/location.service';
import { CreateOrderModalComponent } from '../../../commercial/components/create-order-modal/create-order-modal.component';
import { Negotiation } from '../../../commercial/models/negotiation.model';
import { Order } from '../../../commercial/models/order.model';
import { NegotiationService } from '../../../commercial/services/negotiation.service';
import { Invoice } from '../../../imported-data/models/invoice.model';
import { InvoiceService } from '../../../imported-data/services/invoice.service';

/**
 * Component for the creation of [[BarterProposal|Barter Proposals]].
 *
 * ### Related UI components:
 * - [[ViewBarterComponent]]
 * - [[MyBartersComponent]]
 */
@Component({
  selector: 'app-create-barter',
  templateUrl: './create-barter.component.html',
  styleUrls: ['./create-barter.component.scss']
})
export class CreateBarterComponent implements OnInit, OnDestroy {

  @ViewChild('createOrder') private readonly createOrder: CreateOrderModalComponent;

  public brokers: Company[];
  /** The language currently used. */
  public currentLang: string;
  public company: Company;
  public disableSelectizeFor = {
    supplier: false,
    distributor: false,
    customer: false
  };
  public editingOrder: number;
  public new_order: Order;
  public noResultsFor = {
    supplier: false,
    distributor: false,
    customer: false
  };
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public products: Product[];
  public recipients: Company[];
  public barter_proposal: BarterProposal;
  public relatedInvoices: [];
  public nextInvoice: Invoice[];

  private companyCan: { [action: string]: boolean };
  private subscriptions: Array<any> = [];

  /** @ignore */
  constructor(
    private barterService: BarterService,
    public companyService: CompanyService,
    private componentComm: ComponentCommService,
    private negotiationService: NegotiationService,
    /** @ignore */
    public locationService: LocationService,
    private router: Router,
    private translateService: TranslateService,
    public invoiceService: InvoiceService,
    private route: ActivatedRoute
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.componentComm.emit({ name: 'app-title', title: 'BARTERS.NEW_PROPOSAL' });

    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;
      this.companyCan = companyCan(this.company);

      const canCreateBarter: boolean = this.company.hasModule('barters') &&
        (this.company.hasModule('market') || this.company.hasModule('my-network')) &&
        // this.account.is.commercial &&
        this.companyCan.createBarter; // Grain elevatorss, Brokers, Distributors or Suppliers only

      if (!canCreateBarter) {
        this.router.navigateByUrl('/companies');
        return;
      } else {
        this.setProposal();
        this.checkActivity();

        this.subscriptions.push(this.companyService.getBrokers(company.id, true).subscribe(brokers => {
          this.brokers = brokers.sort((a, b) => a.name.localeCompare(b.name)); // Sorts alphabetically
        }));
      }
    }));
  }

  private setProposal(): void {
    this.barter_proposal = new BarterProposal();

    const fieldSetter = (field: string, value: any) => {
      if (value) this.barter_proposal[field] = value;
    };

    let invoiceId = this.route.snapshot.paramMap.get('invoiceId');

    if (invoiceId) {
      this.processing = true;
      this.subscriptions.push(this.invoiceService.getInvoice(this.company.id, parseInt(invoiceId)).subscribe(invoice => {
        // The invoice must not be settled
        if (!invoice.isSettled) {
          fieldSetter('supplier', invoice.company);
          fieldSetter('distributor', invoice.broker);
          fieldSetter('customer', invoice.recipient);

          this.created(invoice);
        }
        this.processing = false;
      }));
    } else if (this.route.snapshot.queryParams.negotiations) {
      const { negotiations } = this.route.snapshot.queryParams;

      const n = Array.isArray(negotiations) ? negotiations : [negotiations];

      let observables: Observable<Negotiation>[] = [];

      n.forEach(negotiationId => {
        observables.push(this.negotiationService.get(this.company.id, negotiationId));
      });

      this.processing = true;
      this.subscriptions.push(forkJoin(observables).subscribe(response => {
        this.offline(true);

        fieldSetter('supplier', response[0].buyer_represented.length ? response[0].buyer_represented[0] : response[0].buyer);
        fieldSetter('customer', response[0].seller_represented.length ? response[0].seller_represented[0] : response[0].seller);
        this.recipients = [response[0].buyer];

        response.forEach(negotiation => {
          this.barter_proposal.orders.push(negotiation.order);
        });
        this.processing = false;
      }));
    }
  }

  private checkActivity(): void {
    const isDistributor = companyIs(this.company, 'INPUT_DISTRIBUTOR');
    const isSupplier = companyIs(this.company, 'INPUT_SUPPLIER');
    const isBroker = this.company.activity.broker;
    // Based on activity ID?

    // this.disableSelectizeFor.distributor = isDistributor;
    // this.disableSelectizeFor.supplier = isSupplier;

    if (isDistributor) this.barter_proposal.distributor = this.company;
    if (isSupplier) this.barter_proposal.supplier = this.company;
    if (isBroker) this.recipients = [this.company];
  }

  public get exisitngNegotiations(): boolean {
    if (this.barter_proposal.orders.length > 0) {
      return Boolean(this.barter_proposal.orders[0].id);
    }
    return false;
  }

  public saveOrder(): void {
    if (this.editingOrder !== undefined) this.barter_proposal.orders[this.editingOrder] = this.new_order;
    else this.barter_proposal.orders.push(this.new_order);

    this.editingOrder = undefined;
  }

  public addProduct(): void {
    this.editingOrder = undefined;
    this.new_order = new Order();

    // Hardcoded properties
    this.new_order.operation_type = 'venta';

    this.createOrder.show();
  }

  public editProduct(index: number): void {
    this.new_order = this.barter_proposal.orders[index];

    if (this.new_order) {
      this.editingOrder = index;
      this.createOrder.show();
    }
  }

  public removeOrder(index: number): void {
    this.barter_proposal.orders.splice(index, 1);
  }

  public reset(key: string): void {
    this.barter_proposal[key] = null;
  }

  public create(): void {
    this.processing = true;
    this.checkInvoices();
    this.subscriptions.push(this.barterService.createProposal(this.company.id, this.barter_proposal, this.recipients).subscribe(barter_proposal => {
      if (this.company.activity.broker &&
        barter_proposal.barters &&
        barter_proposal.barters[0] &&
        barter_proposal.barters[0].id) {
        // Brokers only create proposals to themselves.
        // Redirects to the newly created proposal.
        this.router.navigate(['company', this.company.id, 'barters', barter_proposal.barters[0].id]);
      } else {
        if (this.barter_proposal.scope === "offline") {
          this.router.navigate(['/company', this.company.id, 'company-traded-orders'], {
            queryParams: {
              order_by: '-original_booking_date',
              'filters[settled]': false
            }
          });
        } else {
          this.router.navigate(['company', this.company.id, 'barters'], {
            queryParams: {
              status_barter_proposals: 1,
              order_by: '-updated_at'
            }
          });
        }
      }
    }));
  }

  private checkInvoices(): void {
    if (this.barter_proposal.invoices) {
      this.barter_proposal.invoices.forEach(bpInvoice => {
        bpInvoice.total = !bpInvoice.invoice.balance || Boolean(bpInvoice.amount.value === bpInvoice.invoice.balance);
      });
    }
  }

  private checkSelectizeEmptyResponse(e): number {
    if (e.query === "") {
      // With an empty query
      if (e.results.length === 1) {
        // Only one result
        return 1;
      } else if (e.results.length === 0) {
        // No results
        return 0;
      }
    }
    return -1;
  }

  public companyTypeLoad(type: string, e, assignIfOne: boolean = true): void {
    let result = this.checkSelectizeEmptyResponse(e);
    if (result === 1 && assignIfOne) {
      this.barter_proposal[type] = e.results[0];
      this.disableSelectizeFor[type] = true;
    } else if (result === 0) {
      this.noResultsFor[type] = true;
    }
  }

  public refreshSelectize: boolean;
  public created(invoice: Invoice): void {
    if (!this.nextInvoice) this.nextInvoice = [];
    this.nextInvoice.push(invoice);
    this.parseInvoices(this.nextInvoice);

    // Hack to refresh the selectize
    this.refreshSelectize = true;
    setTimeout(() => {
      this.refreshSelectize = false;
    });
  }

  public parseInvoices(invoices: Invoice[]): void {
    this.barter_proposal.invoices = [];

    invoices = plainToInstance(Invoice, invoices);
    invoices.forEach(invoice => {
      this.addInvoice(invoice);
    });
  }

  private addInvoice(invoice: Invoice): void {
    let newInvoice: BarterProposalInvoice = {
      invoice: invoice,
      total: true,
      amount: new Price({
        unit: invoice.unit,
        value: invoice.balance - (invoice.settled || 0)
      })
    };

    if (!this.barter_proposal.invoices) this.barter_proposal.invoices = [];
    this.barter_proposal.invoices.push(newInvoice);
  }

  public offline(flag: boolean): void {
    this.barter_proposal.scope = flag ? 'offline' : undefined;
    if (flag) {
      // Offline barters must have:
      // - One recipient
      // - Full valid orders
      if (this.recipients && this.recipients.length > 1) this.recipients = [this.recipients[0]];
      this.barter_proposal.orders = [];
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
