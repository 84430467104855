<ng-template #stoptt>
  <table class="tt-details">
    <tbody>
      <ng-container *ngFor="let detail of details">
        <tr *ngIf="detail.date"
        class="tr-align-center">
          <td class="text-left">{{ detail.label | translate }}</td>
          <td class="text-right">
            <ag-timestamp [date]="detail.date"></ag-timestamp>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-template>

<span
  *ngIf="stop; else none"
  class="label"
  [ngClass]="class"
  [tooltip]="hasTooltip ? stoptt : undefined"
  [delay]="500"
>
  {{ ('SLOTS.STATUS.STOP.' + stop.status.id) | translate }}
</span>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>