import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'replaceWildcards'
})
export class ReplaceWildcardsPipe implements PipeTransform {

  private res = {};

  constructor(private translateService: TranslateService) { }

  private recurse(obj: any, current: string = null): void {
    if (!obj) return;

    for (let key in obj) {
      const value = obj[key];
      const newKey = (current ? current + "." + key : key);
      if (value && typeof value === "object") {
        this.recurse(value, newKey);
      } else {
        this.res[newKey] = value;
      }
    }
  }

  private nl2br(str: string, is_xhtml?: boolean): string {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str.toString()).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  transform(value: string, model?: any): string {
    if (!value) return;

    value = this.nl2br(value);
    this.recurse(model);

    const matches = value.match(/{{([a-zA-Z._]+)}}/gm);
    for (let i in matches) {
      const key = matches[i];
      if (this.res[key] != undefined) {
        value = value.replace(key, this.res[key]);
      } else return value = "<code>" + this.translateService.instant('PROPOSAL.CONTRACT.TO_BE_COMPLETED') + "</code>";
    }

    return value;
  }
}
