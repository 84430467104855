import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, map, mergeMap, startWith } from 'rxjs';

import { JSend } from '../../models/jsend.model';
import { PusherMessage } from '../../models/pusher-message.model';
import { PusherService } from '../../services/pusher.service';
import { FileExtract } from '../models/file-extract.model';

@Injectable({
  providedIn: 'root'
})
export class FileExtractService {

  private baseUrl: string = '/:apiBase/companies/:companyId/file-extract';
  private requestUrl: string = this.baseUrl + '/:requestId';

  constructor(
    private pusherService: PusherService,
    private http: HttpClient
  ) { }

  public extract(companyId: number, sources: string[], formId: string, params?: any): Observable<FileExtract> {
    const url = this.baseUrl
      .replace(":companyId", companyId.toString());

    const payload: {
      sources: string[];
      form: { id: string };
      params?: any;
    } = {
      sources: sources,
      form: { id: formId }
    };

    if (params) payload.params = params;

    const stream = this.http.post<JSend<FileExtract>>(url, payload);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(FileExtract, response.data);
      else throw new Error(String(response.data));
    }));
  }

  private get(companyId: number, requestId: string): Observable<FileExtract> {
    const url = this.requestUrl
      .replace(":companyId", companyId.toString())
      .replace(":requestId", requestId);

    const stream = this.http.get<JSend<FileExtract>>(url);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(FileExtract, response.data);
      else throw new Error(String(response.data));
    }));
  }

  public watch(companyId: number, requestId: string): Observable<FileExtract> {
    return this.pusherService.listen(`company_${companyId}`, 'file_extract').pipe(
      startWith({}),
      mergeMap((event: PusherMessage) => {
        // Update only when this id changed
        if (!event.data ||
          (event.data.id === requestId)) return this.get(companyId, requestId);
      })
    );
  }
}
