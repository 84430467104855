import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

import { FileManagerFile } from '../../../../models/file-manager-file.model';
import { JSend } from '../../../../models/jsend.model';
import { PusherService } from '../../../../services/pusher.service';
import { buildFilters } from '../../../../utilities/filters';
import { Invoice, InvoiceCoverage } from '../models/invoice.model';
import { InvoiceV2 } from '../models/invoicev2.model';

/** [[Invoice]] service. */
@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private baseUrl: string = '/:apiBase/companies/:companyId/invoices';
  private baseUrlV2: string = '/:apiInvoices/companies/:companyId/invoices';
  private importedV2: string = '/:apiInvoices/companies/:companyId/imported-invoices';
  private invoiceById: string = this.baseUrl + '/:invoiceId';
  private invoicePayment: string = this.invoiceById + '/payment-details';
  private invoicePaymentById: string = this.invoicePayment + '/:paymentId';
  private invoicesStats: string = this.baseUrl + '-stats';
  private fileByIdPath: string = this.baseUrlV2 + '/:invoiceId/files/:fileId';
  private importInvoicesPath: string = this.baseUrlV2 + '/import/:cuit';

  /**
   * Maps only those parameters that don't match in the API call.
   * Format - 'Webapp query': 'API query'
   */
  private readonly queryMap: Record<string, string> = {
    'product_id': 'filters[product_id]',
    'recipient_name': 'filters[recipient.name]',
    'reference': 'filters[reference]',
    'commercial_zone': 'filters[commercial_zone]'
  };

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: Invoice[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.baseUrl.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<Invoice[]>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(Invoice, response.body), headers: response.headers };
    }));
  }

  public watch(companyId?: number, filters?: any, paginated: boolean = true): Observable<{ body: Invoice[], headers: HttpHeaders }> {
    return this.pusherService.listen(`company_${companyId}`, 'invoices').pipe(
      startWith({}),
      mergeMap(event => {
        return this.get(companyId, filters, paginated);
      })
    );
  }

  private getV2(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: InvoiceV2[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.baseUrlV2.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<{ data: { invoices: InvoiceV2[] } }>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(InvoiceV2, response.body.data.invoices), headers: response.headers };
    }));
  }

  public getImported(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: InvoiceV2[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.importedV2.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<{ data: { invoices: InvoiceV2[] } }>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(InvoiceV2, response.body.data.invoices), headers: response.headers };
    }));
  }

  public watchV2(companyId?: number, filters?: any, paginated: boolean = true): Observable<{ body: InvoiceV2[], headers: HttpHeaders }> {
    return this.pusherService.listen(`company_${companyId}`, 'invoices').pipe(
      startWith({}),
      mergeMap(event => {
        return this.getV2(companyId, filters, paginated);
      })
    );
  }

  public searchByNumber(companyId: number, recipientName?: string): Function {
    return (reference?: string) => {
      let filters = {
        page: 1,
        order_by: '-date'
      };

      if (reference) filters['reference'] = 'contains:' + reference;
      if (recipientName) filters['recipient_name'] = 'is:' + recipientName;
      // Only non-settled invoices
      filters['filters[settled]'] = 'false';

      let url = this.baseUrl.replace(':companyId', companyId.toString());
      url = buildFilters(url, filters, this.queryMap);
      return url;
    }
  }

  public create(companyId: number, invoice: Invoice): Observable<Invoice> {
    const url = this.baseUrl.replace(':companyId', companyId.toString());

    const stream = this.http.post<Invoice>(url, invoice);
    return stream.pipe(map(response => {
      return plainToInstance(Invoice, response);
    }));
  }

  public edit(companyId: number, invoice: Invoice): Observable<Invoice> {
    const url = this.invoiceById
      .replace(':companyId', companyId.toString())
      .replace(':invoiceId', invoice.id.toString());

    const stream = this.http.put<Invoice>(url, invoice);
    return stream.pipe(map(response => {
      return plainToInstance(Invoice, response);
    }));
  }

  public getInvoice(companyId: number, invoiceId: number): Observable<Invoice> {
    const url = this.invoiceById
      .replace(':companyId', companyId.toString())
      .replace(':invoiceId', invoiceId.toString());

    const stream = this.http.get<Invoice>(url);
    return stream.pipe(map(response => {
      return plainToInstance(Invoice, response);
    }));
  }

  public getSats(companyId: number, filters?: any): Observable<{ body: any, headers: HttpHeaders }> {
    let url = this.invoicesStats.replace(':companyId', companyId.toString());
    url = buildFilters(url, filters, this.queryMap);

    return this.http.get<any>(url, { observe: 'response' });
  }

  public getInvoicePayments(companyId: number, invoiceId: number): Observable<InvoiceCoverage[]> {
    const url = this.invoicePayment
      .replace(':companyId', companyId.toString())
      .replace(':invoiceId', invoiceId.toString());

    return this.http.get<InvoiceCoverage[]>(url);
  }

  public createInvoicePayments(companyId: number, invoiceId: number, coverage: InvoiceCoverage): Observable<InvoiceCoverage> {
    const url = this.invoicePayment
      .replace(':companyId', companyId.toString())
      .replace(':invoiceId', invoiceId.toString());

    return this.http.post<InvoiceCoverage>(url, coverage);
  }

  public deleteInvoicePayments(companyId: number, invoiceId: number, coverage: InvoiceCoverage): Observable<any> {
    const url = this.invoicePaymentById
      .replace(':companyId', companyId.toString())
      .replace(':invoiceId', invoiceId.toString())
      .replace(':paymentId', coverage.id.toString());

    return this.http.delete<any>(url);
  }

  public getFileById(companyId: number, invoice: InvoiceV2): Observable<FileManagerFile> {
    const url = this.fileByIdPath
      .replace(':companyId', String(companyId))
      .replace(':invoiceId', String(invoice.id))
      .replace(':fileId', String(invoice.file_id));

    const stream = this.http.get<JSend<{ file: FileManagerFile }>>(url);

    return stream.pipe(map(response => plainToInstance(FileManagerFile, response.data.file)));
  }

  public import(companyId: number, cuit: string | number): Observable<InvoiceV2[]> {
    const url = this.importInvoicesPath
      .replace(':companyId', String(companyId))
      .replace(':cuit', String(cuit));

    const stream = this.http.post<JSend<{ invoices: InvoiceV2[] }>>(url, {});

    return stream.pipe(map(response => {
      return plainToInstance(InvoiceV2, response.data.invoices);
    }));
  }
}
