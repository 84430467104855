import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { User } from '../../../../../auth/models/user.model';
import { LoginService } from '../../../../../auth/services/login.service';
import { Company } from '../../../../../models/company.model';
import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { CompanyService } from '../../../../../services/company.service';
import { TableFilters } from '../../../../../utilities/table-filters';
import { SlotRequestStatus, SlotsRequest } from '../../../../models/slots-request.model';
import { SlotRequestService } from '../../../../services/slot-request.service';

@Component({
  selector: 'ag-slots-requests-table',
  templateUrl: './slots-requests-table.component.html',
  styleUrls: ['./slots-requests-table.component.scss']
})
export class SlotsRequestsTableComponent extends TableFilters implements OnInit, OnDestroy {

  @Input() public company: Company;
  @Input() public compact: boolean = false;
  @Input() public negotiationId?: number;
  @Input() public contractId?: number;
  @Input() public preLoadedSlotsRequests?: SlotsRequest[];

  @Output('load') readonly onLoad = new EventEmitter();

  public processing: boolean;
  public readonlyUser: boolean;
  /**
   * Current [[User]]
  */
  public user: User;
  public requests: SlotsRequest[];

  private pusherSubscription: Subscription;

  constructor(
    private slotRequestService: SlotRequestService,
    private loginService: LoginService,
    private companyService: CompanyService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      if (!user) return;

      this.user = user;
    }));

    this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
      if (account) {
        // Account changed
        this.readonlyUser = !account.is.logistics && !account.is.commercial && !account.is.commercial_with_users;
      }
    }));

    // Filters based on URL
    this.onFiltersChange = this.loadData;
    this.setupFilters();
  }

  public isRecipient(item: SlotsRequest): boolean {
    return item.recipient.id === this.company.id;
  }

  public canRetire(slotRequest: SlotsRequest): boolean {
    return !slotRequest.expired && !this.isRecipient(slotRequest) && slotRequest.status?.id === SlotRequestStatus.PENDING;
  }

  public canAccept(slotRequest: SlotsRequest): boolean {
    return slotRequest.contract && !slotRequest.expired && this.isRecipient(slotRequest) &&
      (slotRequest.status?.id === SlotRequestStatus.PENDING || slotRequest.status?.id === SlotRequestStatus.ASSIGNED_PARTIALLY);
  }

  public canReject(slotRequest: SlotsRequest): boolean {
    return !slotRequest.expired && this.isRecipient(slotRequest) && slotRequest.status?.id === SlotRequestStatus.PENDING
  }

  public canDelete(slotRequest: SlotsRequest): boolean {
    return slotRequest.company.id === this.company.id && slotRequest.status.id === SlotRequestStatus.PENDING;
  }

  private loadData(): void {
    if (this.processing) return;
    this.processing = true;

    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    const queryParams = this.route.parent.snapshot.queryParams;
    let observable = this.slotRequestService.watch(this.company.id, this.user.id, queryParams);

    if (this.negotiationId) {
      observable = this.slotRequestService.watch(this.company.id, this.user.id, {
        negotiation_id: this.negotiationId,
        validity: 'on_date'
      });
    } else if (this.contractId) {
      observable = this.slotRequestService.watch(this.company.id, this.user.id, {
        'filters[contract][id]': this.contractId,
        validity: 'on_date'
      });
    }

    this.pusherSubscription = observable.subscribe(response => {
      if (!response) return;

      this.dataLoaded(response.body, parsePagination(response.headers));
      this.processing = false;
    });
  }

  public reject(observations: string, slotRequest: SlotsRequest): void {
    this.subscriptions.push(
      this.slotRequestService.reject(this.company.id, slotRequest.id, observations).subscribe(() => { })
    );
  }

  public retire(observations: string, slotRequest: SlotsRequest): void {
    this.subscriptions.push(
      this.slotRequestService.retire(this.company.id, slotRequest.id, observations).subscribe(() => { })
    );
  }

  public delete(observations: string, slotRequest: SlotsRequest): void {
    this.subscriptions.push(
      this.slotRequestService.delete(this.company.id, slotRequest.id, observations).subscribe(() => { })
    );
  }

  public getStatusClass(statusId: number): string {
    const statusClassMap = {
      [SlotRequestStatus.UNKNOWN]: 'text-danger',
      [SlotRequestStatus.PENDING]: 'text-warning',
      [SlotRequestStatus.EXPIRED]: 'text-danger',
      [SlotRequestStatus.ASSIGNED]: 'text-success',
      [SlotRequestStatus.ASSIGNED_PARTIALLY]: 'text-warning',
      [SlotRequestStatus.RETIRED]: 'text-danger',
      [SlotRequestStatus.REJECTED]: 'text-danger'
    };

    return statusClassMap[statusId] ?? '';
  }

  private dataLoaded(data: SlotsRequest[], pagination?: Pagination): void {
    this.requests = data;

    this.onLoad.emit({
      data: this.requests,
      pagination: pagination
    });
  }

  ngOnDestroy(): void {
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    super.ngOnDestroy();
  }
}
