import { Component, ContentChild, ElementRef, EventEmitter, Input, OnDestroy, Output, SkipSelf, ViewChild, forwardRef } from '@angular/core';
import { ControlContainer, NgModelGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Clause } from '../../../../../models/clause.model';
import { Company } from '../../../../../models/company.model';
import { ReplaceWildcardsPipe } from '../../../../pipes/replace-wildcards.pipe';
import { Negotiation } from '../../../commercial/models/negotiation.model';
import { Order } from '../../../commercial/models/order.model';
import { OrderService } from '../../../commercial/services/order.service';
import { ContractClauseGroup } from '../../models/contract-clause-group.model';

/**
 * This component allows to display and edit Contracts.
 *
 * It is responsible for displaying changes, managing translations, creating,
 * editing and deleting [[ContractClause|Clauses]], etc.
 */
@Component({
  selector: 'contract-clauses-form',
  templateUrl: './contract-clauses-form.component.html',
  styleUrls: ['./contract-clauses-form.component.scss'],
  viewProviders: [{
    provide: ControlContainer,// useExisting: NgForm
    useFactory: (container: ControlContainer) => container,
    deps: [[new SkipSelf(), ControlContainer]],
  }]
})
export class ContractClausesFormComponent implements OnDestroy {

  @ViewChild('contractContainer', { static: true }) private readonly contractContainer: ElementRef;

  @Input() set previousContract(value: any) {
    this._previousContract = value;
    this.setClausesBuffer();
  }
  get previousContract(): any {
    return this._previousContract;
  }
  @Input() private company: Company;
  @Input() set contract(value: any) {
    this._contract = value;
    this.refresh();
  }
  get contract(): any {
    return this._contract;
  }
  /** Expected output language */
  @Input() set language(slug: string) {
    if (this._language !== slug) {
      this._language = slug;
      this.refresh();
    }
  }
  @Input() set order(order: Order) {
    if (order) {
      this._order = order;
      this.refresh();
    }
  }
  @Input() set negotiation(negotiation: Negotiation) {
    if (negotiation) {
      this._negotiation = negotiation;
      this.refresh();
    }
  }
  @Input() public negotiability: any;
  @Input() public locations: any;
  @Input() public createOrder: boolean = false;
  @Input() public contractTranslate: boolean = false;
  @Input() public formMode = true;
  @Input() public negotiableMode;
  @Input() public direction;

  @Output() readonly notify = new EventEmitter();

  @ContentChild(forwardRef(() => NgModelGroup), { static: true })
  public modelGroup: NgModelGroup;

  /** Used for rendering purposes */
  public contract_clone: ContractClauseGroup[];
  public wildcards: any;
  public editing: Clause = null;
  public new_clauses: Array<any> = [];
  public maxId: number;
  public maxWasCalculated: boolean = false;
  public clausesBuffer: Array<any> = [];
  public contractHasChanged: number = 0;
  public isNew = true;
  public emptyClause: boolean;

  private _contract: ContractClauseGroup[];
  private _previousContract: any;
  private _language: string;
  private _order: Order;
  private _negotiation: Negotiation;
  private previousContractObject: Object = {};
  private subscriptions: Subscription[] = [];

  constructor(
    private orderService: OrderService,
    private replacePipe: ReplaceWildcardsPipe
  ) { }

  /** Stores a Timeout when true. */
  public refreshInProgress: boolean;
  /**
   * Manage API calls. Evaluate the most efficient way to do it and avoid
   *  making multiple calls in a short time.
   */
  public refresh(): void {
    // First, make sure we have all the data
    if (!this.refreshInProgress && // Check if there is no request in progress
      this.company &&
      this.contract &&
      this._order &&
      this._language) {

      this.refreshInProgress = true;

      // Workaround to update Contract only when the component is visible
      if (this.contractContainer.nativeElement.offsetParent !== null) {
        setTimeout(() => this.callAPI(), 500); // Buffer size in ms
      } else {
        // Not visible, let's check in a second...
        setTimeout(() => {
          this.refreshInProgress = false;
          this.refresh();
        }, 1000);
      }
    }
  }

  private callAPI(): void {
    if (this.contract && this.contract.length) {
      this.subscriptions.push(this.orderService.translateContract(this.company.id, {
        order: this._order,
        negotiation: this._negotiation
      }, this.contract, this._language).subscribe(response => {
        this.refreshInProgress = false;
        this.contract_clone = response.contract;
        this.wildcards = response.wildcards;
        this.setClausesBuffer();
      }));
    } else this.refreshInProgress = false;
  }

  private setClausesBuffer(): void {
    this.new_clauses = [];

    if (this.contract) {
      this.clausesBuffer = [];

      this.contract.forEach((group, i) => {
        group.clauses.forEach((clause, j) => {
          if (clause.editable) {
            // Only parse editable clauses
            let left = clause.text;
            if (this.previousContract) {
              left = this.replacePipe.transform(this.getPreviousClauseById(clause.id).text, this.wildcards);
            }
            let right;
            if (!clause.delete)
              right = this.replacePipe.transform(clause.text, this.wildcards);
            else
              right = "";

            this.clausesBuffer[clause.id] = {
              coords: [i, j],
              originLeft: left,
              originRight: right,
              left: left,
              right: right
            }
          }
        });
      });
    }
  }

  public enableEditor(clause): void {
    this.editing = clause;

    // el timout se usa como hack para que el focus() se ejecute en otro thread
    setTimeout(function (): void {
      document.getElementById('my-editor-' + clause.id).focus();
    }, 0);
  }

  private calculateMaximumId(): void {
    const maxIdArray = [];

    this.contract.forEach(groupOfClauses => {
      maxIdArray.push(Math.max.apply(Math, groupOfClauses.clauses.map(function (o) { return o.id; })));
    });
    this.maxId = Math.max.apply(null, maxIdArray);

    this.maxWasCalculated = true
  }

  public addClause(title): void {
    const contractHasChanged = this.contractHasChanged = this.contractHasChanged + 1;
    const isNew = true;

    this.notify.emit({ contractHasChanged, isNew });

    if (!this.maxWasCalculated)
      this.calculateMaximumId();

    this.maxId += 1;

    let clause = {
      "id": this.maxId,
      "title": title,
      "text": null,
      "delete": false,
      "removable": true,
      "editable": true,
      "negotiable": false
    }

    this.contract.forEach(groupOfClauses => {
      if (groupOfClauses.editable) {
        groupOfClauses.clauses.push(clause);
      }
    });
    this.new_clauses.push(clause.id);
    this.clausesBuffer[clause.id] = {
      originLeft: '',
      originRight: clause.text,
      left: '',
      right: clause.text
    }

    this.enableEditor(clause);
  }

  public compareString(a, b): boolean {
    return (!a && !b) || (a === b);
  }

  public resetClause(clause, isFromHTML?: boolean): void {
    this.clausesBuffer[clause.id].left = this.clausesBuffer[clause.id].originLeft;
    this.clausesBuffer[clause.id].right = this.clausesBuffer[clause.id].originRight;

    if (isFromHTML) {
      const contractHasChanged = this.contractHasChanged = this.contractHasChanged - 1;
      const isNew = contractHasChanged > 0;

      this.notify.emit({ contractHasChanged, isNew });
    }
  }

  public doneEditing(): void {
    this.emptyClause = this.editing.text ? false : true;
    if (!this.createOrder) {
      if (!this.emptyClause) {
        this.resetClause(this.editing, false);

        if (this.clausesBuffer[this.editing.id].right !== this.editing.text) {
          this.clausesBuffer[this.editing.id].left = this.clausesBuffer[this.editing.id].right;
          this.clausesBuffer[this.editing.id].right = this.editing.text;
          const contractHasChanged = this.contractHasChanged = this.contractHasChanged + 1;
          const isNew = true;

          this.notify.emit({ contractHasChanged, isNew });
        }

        this.editing = null;
      }
    } else {
      if (!this.emptyClause) this.editing = null;
    }
  }

  public checkIfClauseWasDeleted(clause: Clause): boolean {
    if (!this.createOrder) return (this.getPreviousClauseByIdDelete(clause.id) && clause.delete);
  }

  private getPreviousClauseByIdDelete(clauseId: number): boolean {
    return this.getPreviousClauseById(clauseId).delete;
  }

  public getPreviousClauseById(clauseId): any {
    let previousClause;

    if (this.previousContract) {
      this.previousContract.some(function (groupOfClauses) {
        groupOfClauses.clauses.some(function (clause) {
          if (clause.id === clauseId) {
            previousClause = clause;
            return true;
          }
        });
        if (previousClause) return true;
      });
    }

    this.previousContractObject[clauseId] = previousClause || { text: false, delete: false };

    return this.previousContractObject[clauseId];
  }

  public deleteClause(clauseId): void {
    this.modelGroup.control.markAsDirty();

    if (!this.maxWasCalculated)
      this.calculateMaximumId();

    const contractHasChanged = this.contractHasChanged = this.contractHasChanged + 1;
    const isNew = true;

    this.notify.emit({ contractHasChanged, isNew });

    this.contract.forEach((groupOfClauses, i) => {

      groupOfClauses.clauses.forEach((clause, j) => {

        if (clause.id === clauseId) {
          if (this.new_clauses.indexOf(clause.id) === -1) {
            this.clausesBuffer[clause.id].left = this.clausesBuffer[clause.id].right;
            this.clausesBuffer[clause.id].right = "";
            Object(this.contract)[i].clauses[j].delete = true;
          } else {
            Object(this.contract)[i].clauses.splice(j, 1);
            this.new_clauses.splice(this.new_clauses.indexOf(clause.id), 1);
          }
        }

      });

    });

  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
