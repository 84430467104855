<cpe-modal #cpeModal [company]="company"></cpe-modal>

<ag-slot-history #historyModal [company]="company"></ag-slot-history>

<div class="actions">
  <!-- Return all/selected slots -->
  <ng-container *ngIf="returnable && slots?.length">
    <agree-confirm 
      title="{{'GLOBAL.ARE_YOU_SURE'|translate}}"
      confirm-title="{{ 'SLOTS.RETURN_BATCH'|translate }}" 
      body="{{ 'SLOTS.RETURN_BATCH_BODY'|translate:{allocator: batch.allocator.name, quantity: !withdrawSelection.length ? slots.length : withdrawSelection.length} }}"
      confirm-button="{{ 'SLOTS.YES_RETURN'|translate }}" 
      cancel-button="{{ 'GLOBAL.CANCEL'|translate }}">
      <button class="btn btn-link btn-sm" 
        (click)="returnSlots(!withdrawSelection.length ? batch.slots : withdrawSelection)"
        [disabled]="processing || disabled">
        <i class="material-symbols-rounded">undo</i> 
        {{ (!withdrawSelection.length ? 'SLOTS.RETURN_BATCH':'SLOTS.RETURN_SELECTED')|translate }}
      </button>
    </agree-confirm>
  </ng-container>

  <!-- Delete all/selected slots -->
  <ng-container *ngIf="deleteable && slots.length && almostOneToDelete">
    <agree-confirm 
      title="{{'GLOBAL.ARE_YOU_SURE'|translate}}"
      confirm-title="{{ 'SLOTS.DELETE_BATCH'|translate }}" 
      body="{{ 'SLOTS.DELETE_BATCH_BODY'|translate:{quantity: withdrawSelection.length ? slotsCanBeDeleted(withdrawSelection).length : slotsCanBeDeleted(slots).length } }}"
      confirm-button="{{ 'GLOBAL.YES_DELETE'|translate }}" 
      cancel-button="{{ 'GLOBAL.CANCEL'|translate }}">
      <button 
        class="btn btn-link btn-sm" 
        (click)="deleteSlots(withdrawSelection.length ? slotsCanBeDeleted(withdrawSelection) : slotsCanBeDeleted(slots))"
        [disabled]="processing || disabled">
        <i class="material-symbols-rounded">delete</i> 
        {{ (withdrawSelection.length ? 'SLOTS.DELETE_SELECTED' :'SLOTS.DELETE_BATCH')|translate }}
      </button>
    </agree-confirm>
  </ng-container>

  <!-- Public Sync -->
  <ng-container *ngIf="synchronizable">
    <button 
      class="btn btn-link btn-sm" 
      (click)="synchTable()"
      [disabled]="processing || disabled || synchronizing">
        <i class="material-symbols-rounded"
        [class.rotating]="synchronizing">
          refresh
        </i>
        {{ 'SLOTS.REFRESH_STATUS_STOP'|translate }}
    </button>
  </ng-container>
</div>

<div class="horizontal-scroller" *ngIf="company && batch && slots?.length">
  <div class="table-sticky-headers">
    <table class="table table-condensed table-hover" sortable defaultSortBy="reference" #tableSortable="tableSortable"
      AgMultiselection [(ngModel)]="withdrawSelection">
      <thead>
        <tr>
          <th class="sap"
          *ngIf="(returnable || canWithdraw || deleteable) &&
          (!batch.allocator || batch.allocator?.id === company.id)">
            <input type="checkbox" AgSelectall
            id="select-all-toggle"
            [disabled]="processing || disabled"
            class="show-onhover no-margin"
            tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
            container="body" [adaptivePosition]="false">
          </th>
          <th class="sap" sortBy="reference">{{ 'SLOTS.ID'|translate }}</th>
          <th class="sap" *ngIf="returnable"></th>
          <th sortBy="holder.name">{{ 'SLOTS.DETAILS_MODAL.HOLDER'|translate }}</th>
          <th sortBy="stop.status.id" colspan="2">
            {{ 'GLOBAL.STATUS'|translate }}
            <ag-refresh-status-stop-button [company]='company'>
            </ag-refresh-status-stop-button>
          </th>
          <th class="sap" sortBy="ctg">{{ "SLOTS.DETAILS_MODAL.CTG_ABBR" | translate }}</th>
          <th class="sap"></th>
          <th class="sap" *ngIf="deleteable"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let slot of slots|orderBy: tableSortable.sortBy; let i = index" class="tr-align-center">
          <td class="sap"
          *ngIf="(returnable || canWithdraw || deleteable) &&
          (!batch.allocator || batch.allocator?.id === company.id)">
            <input [AgCheckbox]="slot" type="checkbox" id="s_{{ i }}_check"
              [disabled]="processing || disabled || (deleteable && slot.holder && !canWithdraw)" class="show-onhover no-margin">
          </td>
          <td class="sap">
            <code>{{ slot.reference }}</code>
          </td>
          <td class="sap text-center" *ngIf="returnable">
            <!-- Return one -->
            <button *ngIf="!withdrawSelection.length" class="btn btn-link btn-xs show-onhover"
              title="{{ 'SLOTS.RETURN'|translate }}" (onConfirm)="returnSlots([slot])" [confirm-options]="{
              title: 'SLOTS.RETURN'|translate,
              body: 'SLOTS.RETURN_BODY'|translate:{allocator: batch.allocator.name, slot: slot.reference},
              action: 'SLOTS.YES_RETURN'|translate
            }" [disabled]="processing || disabled">
              <i class="material-symbols-rounded">undo</i>
            </button>
            <!-- Return multiple -->
            <a *ngIf="withdrawSelection.length" class="small" title="{{ 'SLOTS.RETURN'|translate }}"
              (onConfirm)="returnSlots(withdrawSelection)" [confirm-options]="{
              body: 'SLOTS.RETURN_BATCH_BODY'|translate:{allocator: batch.allocator.name, quantity: withdrawSelection.length},
              action: 'SLOTS.YES_RETURN'|translate
            }" [class.disabled]="processing || disabled">
              <i class="material-symbols-rounded">undo</i>
            </a>
          </td>
          <td>
            <company-info *ngIf="slot.holder" [company]="slot.holder" [activity]="false"></company-info>
          </td>
          <td class="sap">
            <ag-stop-status [slot]="slot"></ag-stop-status>
          </td>
          <td class="sap">
            <i *ngIf="slot.unloaded" tooltip="{{ 'SLOTS.STATUS.STOP.3'|translate }}"
              class="material-symbols-rounded text-success">check_circle</i>
          </td>
          <td class="sap">
            <!-- <samp *ngIf="slot.waybill?.waybill_number" class="small"><a (click)="waybillModal.show(slot.waybill.id)">{{
                slot.waybill.waybill_number }}</a></samp> -->
            <samp *ngIf="slot.ctg" class="small"><a (click)="cpeModal.show(slot.ctg)">{{ slot.ctg }}</a></samp>
          </td>
          <td class="sap text-center">
            <!-- FAS-2119 Slots History button -->
            <a class="small" title="{{ 'SIGNATURES.HISTORY'|translate }}" (click)="historyModal.show(slot)"
              [class.disabled]="processing || disabled">
              <i class="material-symbols-rounded">history</i>
            </a>
          </td>
          <td class="sap text-center" *ngIf="deleteable">
            <!-- Delete button thats handle one or multiple selection -->
            <a class="small" title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}"
              (onConfirm)="deleteSlots(withdrawSelection.length ? withdrawSelection : [slot])" [confirm-options]="
                        withdrawSelection.length ?
                        {
                          body: 'SLOTS.DELETE_BATCH_BODY'|translate:{quantity: withdrawSelection.length},
                          action: 'GLOBAL.YES_DELETE'|translate
                        } :
                        {
                          body: 'SLOTS.DELETE_BODY'|translate:{slot: slot.reference},
                          action: 'GLOBAL.YES_DELETE'|translate 
                        }
                        " [class.disabled]="processing || disabled || slot.holder">
              <i class="material-symbols-rounded">delete</i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-container *ngIf="withdrawSelection.length && canWithdraw">
  <div class="form-group">
    <p [innerHtml]="'SLOTS.DETAILS_MODAL.WITHDRAW_SLOTS'|translate: {quantity: withdrawSelection.length}"></p>
    <textarea [disabled]="processing || disabled" [(ngModel)]="withdrawalReason" class="form-control"
      name="withdrawnReason" rows="6" placeholder="{{ 'SLOTS.REJECT_MODAL.PLACEHOLDER'|translate }}"></textarea>
  </div>
  <div class="text-right">
    <button (click)="withdrawSelectedSlots()" type="button" class="btn btn-agree" [disabled]="processing || disabled">{{
      'GLOBAL.WITHDRAW'|translate }}</button>
  </div>
</ng-container>

<ng-template #editSTOP>
  <a [class.disabled]="processing || disabled" class="small hidden-print" (click)="stopModal.set()">{{
    'SLOTS.STOP.LINK'|translate }}</a>
</ng-template>