import { Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'clause-title-input',
  exportAs: 'clauseTitleInput',
  templateUrl: './clause-title-input.component.html',
  styleUrls: ['./clause-title-input.component.css']
})
export class ClauseTitleInputComponent implements OnInit {

  @ViewChild('clauseTitle', { static: true }) private readonly template: TemplateRef<any>;

  public title;
  modalRef: BsModalRef;

  @Output() onInputTitle = new EventEmitter();

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.title = '';
  }

  showModal(): void {
    this.title = '';
    this.modalRef = this.modalService.show(this.template, {});
  }

  accept(): void {
    this.onInputTitle.emit(this.title);
    this.modalRef.hide();
  }
}
